import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "edit-the-home-screen--home-screen-carousel"
    }}>{`Edit the Home Screen / Home Screen Carousel`}</h1>
    <hr></hr>
    <p>{`To edit the Home Screen or Home Screen Carousel for your mobile app or template, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the Mobile App or Template where you want to edit the Homescreen Tab.`}</li>
      <li parentName="ol">{`Select the Home Screen tab. `}</li>
      <li parentName="ol">{`Click 'Edit Tab - Home Screen'. This action will load the Edit Homescreen Tab page. `}</li>
      <li parentName="ol">{`Set the search field bar to display in the app as either:`}
        <ul parentName="li">
          <li parentName="ul">{`'Always' will display the search bar despite patrons not being authenticated. `}</li>
          <li parentName="ul">{`'Post-Login' will only display the search bar once a patron has been successfully authenticated. `}</li>
          <li parentName="ul">{`'Never' will never display the search bar within your app. If this is the option set, it is recommended that you provide the search functionality within a tab. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`If you wish to allow your patrons to scan an ISBN, enable 'Include ISBN Scanner'.`}</li>
      <li parentName="ol">{`If you wish to have the search results a ordered position, enable 'Include Results Indicator'.`}</li>
      <li parentName="ol">{`To enable the app's homescreen carousel feed, toggle 'Enabled' next to Carousel Feeds. `}</li>
      <li parentName="ol">{`To enable the app's homescreen carousel feed to automatically cycle to show each item in the list, toggle 'Autoscroll'. When disabled, the carousel will not cycle unless the patron manually does it themselves. `}</li>
      <li parentName="ol">{`To have a centered carousel item as the focus, set Display Type to 'CoverFlow'. To have no items receiving focus, set to 'Slide'.`}</li>
      <li parentName="ol">{`Provide a title for the homescreen carousel. `}</li>
      <li parentName="ol">{`Feed 1 will be the carousel that displays on the homescreen of the app. Feeds 2 - 10 are additional carousels that are visible when the patron clicks on 'More' within the app. `}</li>
      <li parentName="ol">{`Provide a name for each carousel you wish to display in the app in the 'Title' field. `}</li>
      <li parentName="ol">{`Set how the carousel items will be identified. You can set this to Biblipgraphic ID, ISBN, or Record Set ID. `}</li>
      <li parentName="ol">{`List the items to include in the carousel. Separate each item by a comma. Only the first 30 items listed for each field will be displayed in your app. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to save the changes. `}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      